<template>
  <Loader v-if="pageLoading" text="Getting all available sets" />
  <div class="office-custom-standard-main" v-else>
    <div class="button-section">
      <Button @click="loadMode = !loadMode" :clearBackground="!loadMode">Load</Button>
      <Button @click="loadMode = !loadMode" :clearBackground="loadMode">New</Button>
    </div>
    
    <Dropdown v-if="loadMode" :items="allAvailableSets" v-model:selected="selectedSet" placeholder="Set" />
    <FormInput v-else placeholder="Enter new set ID" v-model:value="newSetId" />

    <Loader v-if="loadingSet" text="Loading Set" />

    <Dropdown v-if="showSaveButton()" placeholder="Industry" :items="industryListing" v-model:selected="selectedIndustry"
      :disabled="loadMode" />

    <Collapsible v-if="selectedIndustry" title="Department Listing">
      <Collapsible class="department-collapsible" v-for="(d, dInd) in departmentList" :key="dInd" :title="d.value">
        <Collapsible class="position-collapsible" v-for="(p, pInd) in positionList[d.value]" :key="pInd" :title="p.value">
          To add type of seatings
        </Collapsible>
        <Dropdown placeholder="Positions" :items="positionListing" v-model:selected="selectedPosition[d.value]" />
        <Button class="collapsible-button" theme="submit" @click="addPositionClicked(d)">Add Position</Button>
      </Collapsible>
      <Dropdown placeholder="Departments" :items="departmentListing" v-model:selected="selectedDepartment" />
      <Button class="collapsible-button" theme="submit" @click="addDepartmentClicked">Add Department</Button>
    </Collapsible>
    
    <Button v-if="showSaveButton()" class="save-button" theme="submit" @click="saveClicked">Save</Button>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { get, post } from '../../js/apiCall';
import { compareData, showNoti } from '../../js/helper';

// #region Data
const pageLoading = ref(false); // This is use to show when getting all the available sets
const savingStandards = ref(false); // This is used when saving the standards
const loadingSet = ref(false); //This is use to show the loading when getting the set by id

const allAvailableSets = ref([]); // This is the listing of the available sets
const selectedSet = ref(null); // This is the selected set from the dropdown
const loadMode = ref(true); // To set if the page current mode is load or new
const newSetId = ref(''); // This is the new set id for the dropdown

const industryListing = ref([]); //The list of industry
const selectedIndustry = ref(null); //The selected indsutry

const departmentListing = ref([]); //The list of department
const selectedDepartment = ref(null); //The selected department
const departmentList = ref([]); // This is the list of departments that has been added

const positionListing = ref([]); //The list of position
const selectedPosition = ref({}); //The selected position
const positionList = ref({}); // This is the list of positions that has been added

const seatingListing = ref([]); //The list of seating

const originalStandards = ref([]); // This is the original standards from the API
// #endregion Data

// #region Methods
const resetValue = () => {
  // Reset load values
  selectedSet.value = null;
  originalStandards.value = [];

  // Reset new values
  newSetId.value = '';
  selectedIndustry.value = null;
  selectedDepartment.value = null;
  departmentList.value = [];
  selectedPosition.value = {};
  positionList.value = {};
}

const getAllSets = async (init) => {
  pageLoading.value = true;
  const allSets = await get('/CustomOffice/GetComstomOfficeDefaultSets');
  allAvailableSets.value = allSets.map(s => ({ value: s }));

  if (!init) {
    pageLoading.value = false;
  }
}
const getSetById = async () => {
  // Clear the value first
  selectedIndustry.value = null;

  // Getting the set by id
  loadingSet.value = true;
  originalStandards.value = await get(`/CustomOffice/GetCustomOfficeDefaultSet?setId=${selectedSet.value.value}`);
  loadingSet.value = false;

  // Auto populate the details
  newSetId.value = selectedSet.value.value;
  selectedIndustry.value = industryListing.value.find(i => i.uid == originalStandards.value[0].industry_uid);
  departmentList.value = originalStandards.value.map(or => departmentListing.value.find(d => d.uid == or.department_uid));
  const positionSet = {};
  departmentList.value.forEach(d => {
    const currentDept = originalStandards.value.find(o => o.department_uid == d.uid);
    positionSet[d.value] = currentDept.positions.map(curr => positionListing.value.find(p => p.uid == curr.position_uid));
  });
  positionList.value = positionSet;
}

const addDepartmentClicked = () => {
  if (selectedDepartment.value) {
    departmentList.value.push(selectedDepartment.value);
    selectedPosition.value[selectedDepartment.value.value] = null;
    selectedDepartment.value = null;
  }
}
const addPositionClicked = (department) => {
  if (selectedPosition.value[department.value]) {
    if (positionList.value[department.value]) {
      positionList.value[department.value].push(selectedPosition.value[department.value]);
    } else {
      positionList.value[department.value] = [selectedPosition.value[department.value]];
    }

    selectedPosition.value[department.value] = null;
  }
}
const saveClicked = async () => {
  // Must have set id
  if (!newSetId.value) {
    showNoti('Set ID is required!', 'error');
    return;
  }

  // Must have industry selected
  if (!selectedIndustry.value) {
    showNoti('An industry must be selected', 'error');
    return;
  }

  // Must have at least 1 department
  if (departmentList.value.length == 0) {
    showNoti('You must have at least 1 department', 'error');
    return;
  }

  // Each department must have at least 1 position
  const positionIsEmpty = departmentList.value.find(d => positionList.value[d.value] == undefined);
  if (positionIsEmpty) {
    showNoti('Each department must have at least 1 position', 'error');
    return;
  }

  // Formatting the object to save
  const objToSave = departmentList.value.map(d => {
    const allPositions = positionList.value[d.value];
    let uid = 0;
    if (loadMode.value) {
      uid = originalStandards.value.find(o => o.department_uid == d.uid).uid;
    }

    return {
      uid: uid,
      industry_uid: selectedIndustry.value.uid,
      industry_name: selectedIndustry.value.name,
      department_uid: d.uid,
      department_name: d.name,
      position_uids: allPositions.map(p => p.uid).join(','),
      positions: allPositions.map(p => ({ position_uid: p.uid, position_name: p.name })),
      set_id: Number(newSetId.value),
      deleted: 'N',
      action: loadMode.value ? null : 'I'
    }
  });

  const finalObjToSave = compareData(originalStandards, { value: objToSave }, 'uid');
  // Calling update API
  savingStandards.value = true;
  const saveResult = await post('/CustomOffice/UpdateCustomOfficeDefault', finalObjToSave);
  savingStandards.value = false;

  if (saveResult) {
    showNoti('Successfully save your standards.', 'success');
  } else {
    showNoti('There was an error occurred when saving your standards, please try again.', 'error');
  }

  await getAllSets();
}

const showSaveButton = () => {
  if (loadingSet.value) {
    return false;
  }

  if (loadMode.value) {
    return originalStandards.value.length != 0;
  } else {
    return true;
  }
}
// #endregion Methods

// #region Lifecycle
onMounted(async() => {
  pageLoading.value = true;
  
  // Getting all the available sets
  await getAllSets(true);

  // Getting all the categories
  const categoryListing = await get('/CustomOffice/GetCustomOfficeCategories');

  // Getting details for all the categories
  const subCategoryListing = await get('CustomOffice/GetCustomOffices');

  // Formatting to get all the categories
  const allCategories = categoryListing.map(cat => {
    // Get all the subcateogries
    let subcategory = subCategoryListing.filter(sub => sub.category_uid == cat.category_uid);
    subcategory = subcategory.map(sub => ({ ...sub, value: sub.name }));

    return {
      ...cat,
      value: cat.category,
      subCategory: subcategory
    };
  });

  // Getting all the dropdown listing
  industryListing.value = allCategories.find(cat => cat.category_uid == 1).subCategory;
  departmentListing.value = allCategories.find(cat => cat.category_uid == 2).subCategory;
  positionListing.value = allCategories.find(cat => cat.category_uid == 3).subCategory;
  seatingListing.value = allCategories.find(cat => cat.category_uid == 4).subCategory;

  pageLoading.value = false;
});
// #endregion Lifecycle

// #region Watcher
watch(loadMode, () => {
  resetValue();
})
watch(selectedSet, (val) => {
  if (val) {
    getSetById();
  }
})
// #endregion Watcher
</script>

<style scoped>
.office-custom-standard-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.button-section {
  display: flex;
  align-items: center;
  gap: 5px;

  > Button {
    width: fit-content;
  }
}
.department-collapsible {
  background-color: rgb(230, 255, 230);
}
.position-collapsible {
  background-color: rgb(196, 255, 196);
}
.collapsible-button {
  width: fit-content;
  padding-left: 50px;
  padding-right: 50px;
}
.save-button {
  width: fit-content;
  padding-left: 100px;
  padding-right: 100px;
  align-self: flex-end;
}
</style>